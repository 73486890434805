<template>
  <div>
    <loading :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage">
    </loading>

    <div class="row justify-content-md-center">

      <!-- Main card -->
      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12 px-0">
            <div class="card">
              <div class="card-body">

                <!--  Header and Actions -->
                <div class="flexbox px-0 py-3 mb-3">
                  <div class="d-flex align-items-center">
                    <h5 class="box-title mb-3 mt-1">Tipos de Motivos</h5>
                  </div>
                  <div class="btn-group" role="group" aria-label="false">
                    <router-link v-show="isValuationAdmin || isSuperAdmin"
                      :to="{name: 'ValuationIndex'}" class="btn btn-sm btn-outline-light pull-right">
                      Moderação
                    </router-link>
                    <router-link v-show="isValuationAdmin || isSuperAdmin"
                      :to="{name: 'ValuationReasonIndex'}" class="btn btn-sm btn-outline-light pull-right">
                      Motivos
                    </router-link>
                    <router-link v-show="isValuationAdmin || isSuperAdmin"
                      :to="{name: 'TeamIndex'}" class="btn btn-sm btn-outline-light pull-right">
                      Equipes
                    </router-link>
                  </div>
                </div>

                <!-- Table -->
                <div class="table-responsive px-0">
                  <vuetable ref="vuetable"
                    :noDataTemplate="this.$i18n.t('noting to result')"
                    :api-url="endpoint"
                    :fields="vTableFields"
                    :sort-order="sortOrder"
                    :css="css.table"
                    pagination-path="pagination"
                    :per-page="20"
                    :multi-sort="multiSort"
                    :append-params="filter"
                    multi-sort-key="shift"
                    :http-options="httpOptions"
                    track-by="id_process"
                    @vuetable:pagination-data="onPaginationData"
                    @vuetable:loading="onLoading"
                    @vuetable:loaded="onLoaded"
                    @vuetable:cell-clicked="onRowClicked">
                  </vuetable>
                </div>
                <vuetable-pagination ref="pagination"
                  :css="css.pagination" @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
                <!-- END Table -->

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// Basic
import Vue from 'vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Table
import Vuetable, { VuetablePagination } from 'vuetable-2'
// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

import Details from '@/views/Valuation/Reason/Type/Detail.vue'

export default {
  name: 'ValuationReasonTypeList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('valuation.config') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      filter: {},
      endpoint: process.env.VUE_APP_APIENDPOINT + 'v2/valuationreason/type/list',
      multiSort: true,
      someDataList: [],
      vTableFields: [
        {
          name: 'id',
          title: this.$i18n.t('form.valuation.id'),
          sortField: 'id',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome',
          title: this.$i18n.t('form.valuation.name'),
          sortField: 'nome',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'descricao',
          title: this.$i18n.t('form.valuation.description'),
          sortField: 'tipo.nome',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      sortOrder: [
        { field: 'id', direction: 'asc' }
      ],
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  components: {
    Loading,
    Vuetable,
    VuetablePagination
  },
  beforeMount () {
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
  },
  created () {
  },
  methods: {
    buildFilters () {
    },
    applyFilter () {
      this.buildFilters()
      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuickSidebar()
      this.$refs.vuetable.refresh()
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuickSidebar()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()
      this.$refs.vuetable.refresh()
    },
    hideQuickSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onRowClicked (row) {
      this.showDetails(row.data)
    },
    showDetails (data) {
      let me = this
      if (this.$store.getters.userInfo) {
        var ComponentClass = Vue.extend(Details)
        var detailsInstance = new ComponentClass({
          data: {
            user: me.user,
            type: data
          }
        })
        detailsInstance.$mount()
        let detailsProcessContent = detailsInstance.$el
        global.instanceApp.$alertSwal.modalAlert(null, detailsProcessContent, null,
          'no-padding', { showConfirmButton: false }).then((result) => { })
      }
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()

      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    onDelete (id) {
      // this.$alertSwal.modalConfirm('', 'Você tem certeza que deseja excluir este registro?',
      //   'warning', 'Sim', 'Não', (e) => this.deleteValuation(id)).then((result) => {
      //   if (result.value) {
      //     this.$alertSwal.toast('Registro excluído com sucesso!')
      //   } else {
      //     this.$alertSwal.toast('Não foi possível excluir o registro!', 'error')
      //   }

      //   this.$refs.vuetable.refresh()
      // })
    },
    async deleteValuation (id) {
      // let _this = this
      // _this.isLoading = true
      // await ValuationService.deleteValuation(id).then(res => {
      //   // if (res.httpCode === 200 && res.data.status === 'ok') {
      //   _this.isLoading = false
      // }).catch(() => {
      //   _this.isLoading = false
      // })
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isValuationAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_VALUATION_MANAGER') !== -1) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>

</style>
